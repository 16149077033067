import React, { Component } from 'react';
import { Link } from "gatsby"


class HomeFeature extends Component {
  render() {
    return (
      <>
    <section className="fdb-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-xl-5">
            <h2 className="text-h1">Pseudo gaming pour jeux vidéo</h2>
            <p className="lead">Trouvez un pseudo pour un jeu vidéo grâce à nos générateurs.</p>

            <div className="row pt-4 pt-xl-5">
              <div className="col-12 col-md-5">
                <h3><Link to="/pseudo-fortnite/">Fortnite</Link></h3>
              </div>
              <div className="col-12 col-md-5 pt-3 pt-md-0">
                <h3><Link to="/pseudo-minecraft/">Minecraft</Link></h3>
              </div>
              <div className="col-12 col-md-5 pt-3 pt-md-0">
                <h3><Link to="/pseudo-runescape/">RuneScape</Link></h3>
              </div>
              <div className="col-12 col-md-5 pt-3 pt-md-0">
                <h3><Link to="/pseudo-world-of-warcraft/">World Of Warcraft</Link></h3>
              </div>
              <div className="col-12 col-md-5 pt-3 pt-md-0">
                <h3><Link to="/pseudo-rust/">Rust</Link></h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-6 m-auto mr-lg-0 ml-lg-auto pt-5 pt-lg-0 order-top">
            <img alt="pseudo pour jeux vidéo" className="img-fluid" src="/imgs/illustrations/pseudo-jeux.svg" />
          </div>
        </div>
      </div>
    </section>
    <section className="fdb-block">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-8 col-lg-6 m-auto mr-lg-0 ml-lg-auto pt-5 pt-lg-0 order-top">
          <img alt="pseudo pour jeux vidéo" className="img-fluid" src="/imgs/illustrations/pseudo-reseaux-sociaux.svg" />
        </div>
        <div className="col-12 col-lg-6 col-xl-5">
          <h2 className="text-h1">Pseudo pour réseaux sociaux</h2>
          <p className="lead">Facebook, Twitter, Instagram, Pinterest, Discord ... Trouvez un pseudo pour tout vos réseaux sociaux.</p>
          <div className="row pt-4 pt-xl-5">
            <div className="col-12 col-md-5">
              <h3><Link to="/pseudo-instagram/">Instagram</Link></h3>
            </div>
            <div className="col-12 col-md-5 pt-3 pt-md-0">
              <h3><Link to="/pseudo-discord/">Discord</Link></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
    )
  }
}

export default HomeFeature
